<template>
  <div>
    <CRow>
      <CCol>
        <CCard>
          <CCardHeader>
            <CIcon name="cil-spreadsheet" />
            <strong> {{ $t("user.newrole.title") }}</strong>
          </CCardHeader>
          <CCardBody>
            <form-wizard
              ref="NewProdWiz"
              title=""
              subtitle=""
              :nextButtonText="$t('common.wizard.next')"
              :backButtonText="$t('common.wizard.back')"
              :finishButtonText="$t('common.wizard.save')"
              color="#000"
              error-color="#e74c3c"
              @on-complete="onComplete"
              @on-loading="setLoading"
              @on-validate="handleValidation"
              @on-error="handleErrorMessage"
            >
              <tab-content
                :title="$t('wizard.newrole.step1.title')"
                :before-change="beforeTabSwitch0"
              >
                <Formatter
                  :config="new_step_0"
                  :dataForm="dataForm"
                  v-on="$listeners"
                />
              </tab-content>
              <tab-content
                :title="$t('wizard.newrole.step2.title')"
                :before-change="beforeTabSwitch1"
              >
                <Formatter
                  :config="new_step_1"
                  :dataForm="dataForm"
                  v-on="$listeners"
                />
              </tab-content>
              <tab-content
                :title="$t('wizard.newrole.step3.title')"
                :before-change="beforeTabSwitch2"
              >
                <Formatter
                  :config="new_step_2"
                  :dataForm="dataForm"
                  v-on="$listeners"
                />
              </tab-content>
              <!-- <tab-content title="Dati economici" :before-change_="beforeTabSwitch3">
        <Formatter
          :config="new_step_3"
          :dataForm="dataForm"
          v-on="$listeners"
        />
      </tab-content> -->
              <div class="loading" v-if="loadingWizard">
                <CSpinner color="secondary" size="lg" />
              </div>
              <div v-if="errorMsg">
                <CAlert show color="danger">{{ errorMsg }}</CAlert>
              </div>
            </form-wizard>
            <template v-slot:header-wrapper>
              <span></span>
            </template>
            <template v-slot:footer-wrapper>
              <span></span>
            </template>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import Vue from "vue";
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import Formatter from "../../common/form/Formatter";

import { GetDataManager } from "../../ds/index";
import { Query } from "@syncfusion/ej2-data";
import GetOptions from "../../common/form/options";

function index(obj, is, value) {
  if (typeof is == "string") return index(obj, is.split("."), value);
  else if (is.length == 1 && value !== undefined) return (obj[is[0]] = value);
  else if (is.length == 0) return obj;
  else return index(obj[is[0]], is.slice(1), value);
}

export default Vue.extend({
  components: {
    FormWizard,
    TabContent,
    Formatter,
  },
  data() {
    return {
      loadingWizard: false,
      errorMsg: null,
      // need_nation_options: {},
      baseDataForm: {
        // Form is loading
        loading: false,
        // Edit Mode
        active: true,
        // Form errors
        errors: {},
        // Form original data
        dataOrig: {},
        formData: {
          // availability_limitless: true,
          // availability_over: false,
          // availability_xd: false,
          // pw: false,
          // production_type_id: 1,
          // tax_rate_id: 1,
          // productproducer_id: 0,
          // producer_id: this.$store.state.role.producer__id,
          // category__macrocategory_id: 0,
          // category__parent_id: 0,
          // ageclass_id: 1,
          // certifications: [],
          // image: "",
          // image_label: "",
          tax_exempt: null,
          family: {
            f_type: null,
            name: "",
            slug: "",
          },
          r_type: 2,
        },
        cur_lang: this.$i18n.locale,
      },
    };
  },
  created() {
    // GetOptions({
    //   dm: GetDataManager("structure_production"),
    //   query_cache: "structure_production",
    //   reducer: function (map, obj) {
    //     if (obj.need_nation) {
    //       map.push(obj.id);
    //     }
    //     return map;
    //   },
    //   reducer_cache: "structure_production_need_nation",
    // }).then((e) => {
    //   this.need_nation_options = e;
    // });
  },
  computed: {
    dataForm() {
      let res = this.baseDataForm;
      let translations = {};
      translations[this.$i18n.locale] = {};
      res.formData.translations = translations;
      return res;
    },
    // need_country() {
    //   return this.need_nation_options.length > 0
    //     ? this.need_nation_options.includes(
    //         this.dataForm.formData.production_type_id
    //       )
    //     : false;
    // },
    new_step_0() {
      return {
        fields: {
          r_type: {
            title: this.$t("models.role.r_type"),
            description: this.$t("models.role.r_type_info"),
            type: "CInputRadioGroup",
            size: 12,
            subsize: [2, 10],
            options: [
              // { value: 5, label: this.$t("models.role.r_type_option5_info") },
              // { value: 4, label: this.$t("models.role.r_type_option4_info") },
              { value: 2, label: this.$t("models.role.r_type_option2_info") },
              // { value: 3, label: this.$t("models.role.r_type_option3_info") },
              // { value: 1, label: this.$t("models.role.r_type_option1_info") }
            ],
          },
        },
      };
    },
    new_step_1() {
      console.log(this.dataForm.formData.family.f_type);
      return {
        fields: {
          name: {
            title: this.$t("models.role.name"),
            description: this.$t("models.role.name_info"),
            type: "CInput",
            size: 12,
            subsize: [2, 10],
          },
          "family.name": {
            title: this.$t("models.family.name"),
            description: this.$t("models.family.name_info"),
            type: "CInput",
            size: 12,
            subsize: [2, 10],
            // enabled: this.role.family.f_type!="1",
          },
          "family.slug": {
            title: this.$t("models.family.slug"),
            description: this.$t("models.family.slug_info"),
            type: "CInput",
            size: 12,
            subsize: [2, 10],
          },
          country_id: {
            title: this.$t("models.role.country"),
            description: this.$t("models.role.country_info"),
            type: "CSelect",
            size: 12,
            subsize: [2, 10],
            options: GetOptions({
              dm: GetDataManager("geo_country"),
              query: new Query().where("status", "equal", 100),
              reducer: function (map, obj) {
                map.push({ value: obj.code, label: obj.name });
                return map;
              },
              reducer_cache: "geo_country_active",
            }),
          },
          tel: {
            title: this.$t("models.role.phone"),
            description: this.$t("models.role.phone_info"),
            type: "CInput",
            size: 12,
            subsize: [2, 10],
          },
          url: {
            title: this.$t("models.role.web"),
            description: this.$t("models.role.web_info"),
            type: "CInput",
            size: 12,
            subsize: [2, 10],
          },
        },
      };
    },
    new_step_2() {
      return {
        fields: {
          "family.f_type": {
            title: this.$t("models.family.f_type"),
            type: "CInputRadioGroup",
            size: 12,
            subsize: [2, 10],
            options: [
              {
                value: "1",
                label: this.$t("models.family.f_type_option1_info"),
              },
              {
                value: "2",
                label: this.$t("models.family.f_type_option2_info"),
              },
              {
                value: "3",
                label: this.$t("models.family.f_type_option3_info"),
              },
            ],
            description: this.$t("models.family.f_type_info"),
          },
          tax_exempt: {
            title: this.$t("models.role.tax_exempt"),
            description: this.$t("models.role.tax_exempt_info"),
            type: "CInputRadioGroup",
            size: 12,
            subsize: [2, 10],
            options: [
              { value: false, label: this.$t("common.bool.true") },
              { value: true, label: this.$t("common.bool.false") },
            ],
            enabled: ["2", "3"].includes(this.dataForm.formData.family.f_type),
          },
          tax_number: {
            title: this.$t("models.role.tax_number"),
            description: this.$t("models.role.tax_number_info"),
            type: "CInput",
            size: 12,
            subsize: [2, 10],
            enabled: this.dataForm.formData.tax_exempt == false,
          },
          iban: {
            title: this.$t("models.family_wallet.iban"),
            description: this.$t("models.family_wallet.iban_info"),
            type: "CInput",
            size: 12,
            subsize: [2, 10],
            // enabled: this.role.family.f_type!="1" && this.role.tax_exempt==false,
          },
          bic: {
            title: this.$t("models.family_wallet.bic"),
            description: this.$t("models.family_wallet.bic_info"),
            type: "CInput",
            size: 12,
            subsize: [2, 10],
            // enabled: this.role.family.f_type!="1" && this.role.tax_exempt==false,
          },
          // currency_id: {
          //   title: this.$t('models.family_wallet.currency'),
          //   description: this.$t('models.family_wallet.currency_info'),
          //   type: "CSelect",
          //   size: 12,
          //   subsize: [2, 10],
          //   options: GetOptions({
          //     dm: GetDataManager("geo_country"),
          //     reducer: function (map, obj) {
          //       map.push({ value: obj.code, label: obj.name });
          //       return map;
          //     },
          //     reducer_cache: "geo_country",
          //   }),
          // },
        },
      };
    },
    new_step_3() {
      return {
        fields: {
          //   availability_limitless: {
          //     title: "Disponibilità",
          //     type: "CInputRadioGroup",
          //     size: 12,
          //     subsize: [3, 9],
          //     options: [
          //       { value: true, label: "Illimitata" },
          //       { value: false, label: "Limitata" },
          //     ],
          //     description: "compila il campo",
          //   },
          //   availability_start: {
          //     title: "Quanità disponibile",
          //     description: "compila il campo",
          //     type: "CNumber",
          //     size: 12,
          //     subsize: [3, 9],
          //     hide: true,
          //     enabled: !this.dataForm.formData.availability_limitless,
          //   },
          //   availability_xd: {
          //     title: "Quantità disponibile per",
          //     type: "CInputRadioGroup",
          //     size: 12,
          //     subsize: [3, 9],
          //     options: [
          //       { value: true, label: "A consegna" },
          //       { value: false, label: "In totale" },
          //     ],
          //     enabled: !this.dataForm.formData.availability_limitless,
          //   },
          //   availability_over: {
          //     title: "Accetta ordini a quantità esaurita",
          //     type: "CInputRadioGroup",
          //     size: 12,
          //     subsize: [3, 9],
          //     options: [
          //       { value: true, label: "Si" },
          //       { value: false, label: "No" },
          //     ],
          //     description: "compila il campo",
          //     enabled: !this.dataForm.formData.availability_limitless,
          //   },
        },
      };
    },
  },
  methods: {
    setLoading: function (value) {
      this.loadingWizard = value;
    },
    handleValidation: function (isValid, tabIndex) {
      console.log("Tab: " + tabIndex + " valid: " + isValid);
    },
    handleErrorMessage: function (errorMsg) {
      this.errorMsg = errorMsg;
    },
    onComplete: function () {
      this.dataForm.loading = true;
      this.dataForm.errors = {};

      let params = Object.assign({}, this.dataForm.formData.family);
      Object.keys(params).forEach(function (key) {
        index(params, key, params[key]);
      });
      // params.productproducer_id = this.pp.id;
      //   'translations.it.name'.split('.').reduce((o,i)=>o[i], params)
      GetDataManager("auth_family")
        .insert(params)
        .then(
          (e) => {
            // console.log("after update");
            // console.log(e);
            // this.$emit("data_updated", e);
            // this.dataOrigOptions = JSON.parse(
            //   JSON.stringify(this.dataForm.formDataOptions)
            // );
            // let product_id = e.id;
            let params = Object.assign({}, this.dataForm.formData);
            let family_id = e.id;
            params.family_id = family_id;
            GetDataManager("auth_role")
              .insert(params)
              .then(
                (e) => {
                  let role_id = e.id;
                  GetDataManager("auth_fwallet", [family_id])
                    .insert(params)
                    .then(
                      (e) => {
                        this.baseDataForm.dataOrig = e;
                        // this.$emit("change", false);

                        const self = this;

                        this.$store
                          .dispatch("connections/fetchAll")
                          .then(() => {
                            self.$refs.NewProdWiz.reset();
                            self.dataForm.loading = false;
                            self.$store
                              .dispatch("connections/fetchByRoleId", role_id)
                              .then(({ connection, role }) => {
                                self.$store.commit("setRole", role);
                                self.$store.commit(
                                  "connections/setCurrent",
                                  connection
                                );
                                self.$store.dispatch("toaster/add", {
                                  title: "Creazione ruolo",
                                  text: "Ruolo creato con successo",
                                  autohide: true,
                                });
                                this.$router.push({
                                  name: "RoleDashboard",
                                  params: { id: role_id },
                                });
                              });
                          });
                      },
                      (reason) => {
                        let errors = JSON.parse(reason[0].error.response);
                        this.dataForm.errors = errors;
                        this.dataForm.loading = false;
                      }
                    );
                },
                (reason) => {
                  let errors = JSON.parse(reason[0].error.response);
                  this.dataForm.errors = errors;
                  this.dataForm.loading = false;
                }
              );
          },
          (reason) => {
            let errors = JSON.parse(reason[0].error.response);
            this.dataForm.errors = errors;
            this.dataForm.loading = false;
          }
        );
    },
    beforeTabSwitch0: function () {
      console.log("This is called before switching tabs 0");
      return this.validateProdData(this.new_step_0);
    },
    beforeTabSwitch1: function () {
      console.log("This is called before switching tabs 1");
      // this.validateData(this.new_step_1)
      return this.validateData(this.new_step_1);
    },
    beforeTabSwitch2: function () {
      console.log("This is called before switching tabs 2");
      return this.validateData(this.new_step_2);
    },
    beforeTabSwitch3: function () {
      console.log("This is called before switching tabs 3");
      return this.validateData(this.new_step_3);
    },
    validateData: function (config) {
      console.log("Validate data");
      return new Promise((resolve, reject) => {
        this.dataForm.loading = true;
        this.dataForm.errors = {};

        let params = Object.assign({}, this.dataForm.formData.family);
        Object.keys(params).forEach(function (key) {
          index(params, key, params[key]);
        });
        // params.productproducer_id = this.pp.id;
        //   'translations.it.name'.split('.').reduce((o,i)=>o[i], params)
        GetDataManager("auth_family_cdr")
          .insert(params)
          .then(
            () => {
              resolve(true);
            },
            (reason) => {
              let errors = JSON.parse(reason[0].error.response);
              this.dataForm.errors = errors;
              let valid = true;
              Object.keys(errors).forEach(function (key) {
                const transFields = Object.keys(config.fields).filter((field) =>
                  field.startsWith(key + ".")
                );
                const childFields = Object.keys(config.fields).reduce(function (
                  map,
                  fkey
                ) {
                  if (config.fields[fkey].children) {
                    map.push.apply(
                      map,
                      Object.keys(config.fields[fkey].children).reduce(
                        function (map, ffkey) {
                          map.push(ffkey);
                          return map;
                        },
                        []
                      )
                    );
                  }
                  return map;
                },
                []);
                console.log(key, childFields.includes(key), childFields);

                if (Object.keys(config.fields).includes(key)) {
                  valid = false;
                } else if (transFields.length > 0) {
                  valid = false;
                } else if (childFields.includes(key) > 0) {
                  valid = false;
                }
              });

              if (valid == true) {
                this.dataForm.errors = {};
                // this.validateProdData(config)
                resolve(true);
              } else {
                reject(this.$t("common.wizard.required"));
                // this.validateProdData(config)
              }

              //   this.dataForm.loading = false;
            }
          );
        // setTimeout(() => {
        //   if (this.count < 1) {
        //     this.count++;
        //     reject(
        //       "This is a custom validation error message. Click next again to get rid of the validation"
        //     );
        //   } else {
        //     this.count = 0;
        //     resolve(true);
        //   }
        // }, 1000);
      });
    },
    validateProdData: function (config) {
      console.log("Validate data Prod");
      return new Promise((resolve, reject) => {
        this.dataForm.loading = true;
        this.dataForm.errors = {};

        let params = Object.assign({}, this.dataForm.formData);
        Object.keys(params).forEach(function (key) {
          index(params, key, params[key]);
        });
        // params.productproducer_id = this.pp.id;
        //   'translations.it.name'.split('.').reduce((o,i)=>o[i], params)
        GetDataManager("auth_role_cdr")
          .insert(params)
          .then(
            () => {
              resolve(true);
            },
            (reason) => {
              let errors = JSON.parse(reason[0].error.response);
              this.dataForm.errors = errors;
              let valid = true;
              Object.keys(errors).forEach(function (key) {
                const transFields = Object.keys(config.fields).filter((field) =>
                  field.startsWith(key + ".")
                );
                const childFields = Object.keys(config.fields).reduce(function (
                  map,
                  fkey
                ) {
                  if (config.fields[fkey].children) {
                    map.push.apply(
                      map,
                      Object.keys(config.fields[fkey].children).reduce(
                        function (map, ffkey) {
                          map.push(ffkey);
                          return map;
                        },
                        []
                      )
                    );
                  }
                  return map;
                },
                []);
                console.log(key, childFields.includes(key), childFields);

                if (Object.keys(config.fields).includes(key)) {
                  valid = false;
                } else if (transFields.length > 0) {
                  valid = false;
                } else if (childFields.includes(key) > 0) {
                  valid = false;
                }
              });

              if (valid == true) {
                this.dataForm.errors = {};
                resolve(true);
              } else {
                reject(this.$t("common.wizard.required"));
              }

              //   this.dataForm.loading = false;
            }
          );
        // setTimeout(() => {
        //   if (this.count < 1) {
        //     this.count++;
        //     reject(
        //       "This is a custom validation error message. Click next again to get rid of the validation"
        //     );
        //   } else {
        //     this.count = 0;
        //     resolve(true);
        //   }
        // }, 1000);
      });
    },
  },
});
</script>
